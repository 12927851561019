import { message } from 'antd'
import axios from 'axios'
import { useCallback } from 'react'

import { API_URL } from '../constants'

const getPresignedUrl = (key, command) =>
  axios
    .post(`${API_URL}/file-upload-url`, { key, command })
    .then(res => {
      return res.data
    })
    .catch(() => {
      return null
    })

export const useUploadFile = () => {
  const getS3Key = (userId, filename, folder) => {
    const profileImageFolder = 'uploads/profile-images'
    const key = `${folder || profileImageFolder}/${userId}_${Date.now()}_${filename}`

    return key
  }

  const uploadFile = useCallback(async (file, key, resourcePath, setProgress) => {
    if (file && key && resourcePath && setProgress) {
      setProgress(true)

      const presignedUrl = await getPresignedUrl(key, 'put')

      if (!presignedUrl) {
        message.error('Oops, something went wrong.')
        return false
      }

      const uninterceptedAxiosInstance = axios.create()

      await uninterceptedAxiosInstance
        .put(presignedUrl.presignedUrl, file, {
          headers: {
            'Content-Type': file.type,
          },
        })
        .catch(() => {
          message.error('Oops, something went wrong.')
          setProgress(false)
          throw new Error('Upload failed')
        })

      await axios
        .post(`${API_URL}/${resourcePath}`, {
          destination: key,
        })
        .catch(() => {
          message.error('Oops, something went wrong.')
          setProgress(false)
          throw new Error('Upload failed')
        })

      setProgress(false)
    }
  }, [])

  return {
    uploadFile,
    getS3Key,
  }
}
